<template>
	<page-layout bgColor="#f2f2f2" page-name="申请提款" :show-home="false" style="height: 100vh">
		<div class="main">
			<van-field v-model="code" label="提款码" placeholder="请输入提款码"></van-field>
			<van-button
							type="primary"
							block
							:disabled="disabled"
							:loading="loading"
							style="margin-top: 20px; height: 40px"
							@click="withdrawalsHandle"
			>提款</van-button>
			<div class="tips">
				<div>提示：提款码请联系客户经理获取</div>
			</div>
		</div>
	</page-layout>
</template>

<script>
	import { Field, Button, Dialog, Toast } from 'vant'
	import ApplyApi from '../../api/apply'

	export default {
		name: "Withdrawals",
		components: {
			[Field.name]: Field,
			[Button.name]: Button
		},
		props: {
			orderId: String
		},
		data() {
			return {
				code: '',
				disabled: true,
				loading: false
			}
		},
		mounted() {
			this.validateOrder()
		},
		methods: {
			validateOrder() {
				Toast.loading({
					duration: 0,
					message: '加载中'
				})
				ApplyApi.validateOrder(this.orderId).then(res => {
					if (!res.data) {
						Dialog({
							message: '订单不存在',
							showConfirmButton: false
						})
					} else {
						this.disabled = false
					}
				}).finally(() => {
					Toast.clear()
				})
			},
			withdrawalsHandle() {
				if (!this.code) {
					Dialog({
						message: '请输入提款码'
					})
					return;
				}
				this.loading = true
				ApplyApi.withdrawals(this.orderId, this.code).then(res => {
					if (res.data.validCode) {
						location.href = res.data.redirect
					} else {
						this.loading = false
						Dialog({
							message: '提款码错误'
						})
					}
				}).catch(() => {
					this.loading = false
				})
			}
		}
	}
</script>

<style scoped>
	.main {
		margin-top: 50px;
		padding: 30px;
		font-size: 28px;
	}
	.tips {
		color: #666666;
		margin-top: 80px;
	}
</style>